<template>
  <div>
    <table class="tbl_row">
      <colgroup>
        <col width="140">
        <col>
        <col width="140">
        <col>
      </colgroup>
      <tbody>
        <tr>
          <th rowspan="3">출발지</th>
          <td>
            <e-auto-complete-place @change="changePol" :ctr-cd="pol.ctrCd" :plc-cd="pol.plcCd" />
          </td>
          <th rowspan="3">도착지</th>
          <td>
            <e-auto-complete-place @change="changePod" :ctr-cd="pod.ctrCd" :plc-cd="pod.plcCd" />
          </td>
        </tr>
        <tr>
          <td>
            {{ pol.ctrCd }} ({{ pol.ctrEnm }}) / {{ pol.plcCd }} ({{ pol.plcEnm }})
          </td>
          <td>
            {{ pod.ctrCd }} ({{ pod.ctrEnm }}) / {{ pod.plcCd }} ({{ pod.plcEnm }})
          </td>
        </tr>
        <tr>
          <td>
            <input type="text" v-model="tempCtrCd" style="width:100px;" placeholder="ctr_cd" />
            <input type="text" v-model="tempPlcCd" style="width:100px;" placeholder="plc_cd"/>
            <button class="button gray sm" @click.prevent="apply()">적용</button>
          </td>
          <td>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'

export default {
  name: 'SampleAutoComplete',
  components: {
    EAutoCompletePlace
  },
  data: function () {
    return {
      test: '',
      tempCtrCd: '',
      tempPlcCd: '',
      pol: {
        ctrCd: '',
        plcCd: ''
      },
      pod: {
        ctrCd: 'HK',
        plcCd: 'HKG'
      }
    }
  },
  methods: {
    apply () {
      this.pol.ctrCd = this.tempCtrCd
      this.pol.plcCd = this.tempPlcCd
    },
    changePol (vo) {
      this.pol = vo
    },
    changePod (vo) {
      this.pod = vo
    }
  }
}

</script>
