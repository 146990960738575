var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "tbl_row" }, [
      _vm._m(0),
      _c("tbody", [
        _c("tr", [
          _c("th", { attrs: { rowspan: "3" } }, [_vm._v("출발지")]),
          _c(
            "td",
            [
              _c("e-auto-complete-place", {
                attrs: { "ctr-cd": _vm.pol.ctrCd, "plc-cd": _vm.pol.plcCd },
                on: { change: _vm.changePol },
              }),
            ],
            1
          ),
          _c("th", { attrs: { rowspan: "3" } }, [_vm._v("도착지")]),
          _c(
            "td",
            [
              _c("e-auto-complete-place", {
                attrs: { "ctr-cd": _vm.pod.ctrCd, "plc-cd": _vm.pod.plcCd },
                on: { change: _vm.changePod },
              }),
            ],
            1
          ),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              " " +
                _vm._s(_vm.pol.ctrCd) +
                " (" +
                _vm._s(_vm.pol.ctrEnm) +
                ") / " +
                _vm._s(_vm.pol.plcCd) +
                " (" +
                _vm._s(_vm.pol.plcEnm) +
                ") "
            ),
          ]),
          _c("td", [
            _vm._v(
              " " +
                _vm._s(_vm.pod.ctrCd) +
                " (" +
                _vm._s(_vm.pod.ctrEnm) +
                ") / " +
                _vm._s(_vm.pod.plcCd) +
                " (" +
                _vm._s(_vm.pod.plcEnm) +
                ") "
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.tempCtrCd,
                  expression: "tempCtrCd",
                },
              ],
              staticStyle: { width: "100px" },
              attrs: { type: "text", placeholder: "ctr_cd" },
              domProps: { value: _vm.tempCtrCd },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.tempCtrCd = $event.target.value
                },
              },
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.tempPlcCd,
                  expression: "tempPlcCd",
                },
              ],
              staticStyle: { width: "100px" },
              attrs: { type: "text", placeholder: "plc_cd" },
              domProps: { value: _vm.tempPlcCd },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.tempPlcCd = $event.target.value
                },
              },
            }),
            _c(
              "button",
              {
                staticClass: "button gray sm",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.apply()
                  },
                },
              },
              [_vm._v("적용")]
            ),
          ]),
          _c("td"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "140" } }),
      _c("col"),
      _c("col", { attrs: { width: "140" } }),
      _c("col"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }